<template>
  <v-app>
    <router-view :key="refresh"/>
  </v-app>
</template>

<script>

export default {
  name: 'applabs2',
  components: {},
  computed: {
  },
  data () {
    return {
      refresh: 0,
    }
  },
  created: function() {
    var self = this;
    this.log("Created");
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
  },
  methods: {

  }
}
</script>
